<template>
  <v-dialog
    v-model="newEntryDialog"
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        class="rounded ma-2"
        tile
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Assign Entry Values
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <template v-for="(entry, i) in productEntrySlots">
              <v-col
                v-if="entries.length > 0"
                :key="entry._id"
                cols="12"
                sm="6"
              >
                <template v-if="entry.prefix.length > 1">
                  <v-select
                    v-model="entries[i].prefix"
                    :items="entry.prefix"
                    label="Select Prefix"
                  />
                  <v-text-field
                    v-model="entries[i].value"
                    :label="entry.name"
                    :type="entry.type"
                    :rules="(entry.type == 'Number' ? numberRules : [])"
                  />
                </template>
                <template v-else>
                  <v-text-field
                    v-model="entries[i].value"
                    :type="entry.type"
                    :label="entry.name"
                    :prefix="entry.prefix[0]"
                  />
                </template>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text v-if="orderEntryConflicts.length > 0">
        The following user prefered entries are not available!
        Please assign a replacement entry:

        <ul>
          <li
            v-for="orderEntry in orderEntryConflicts"
            :key="orderEntry._id"
          >
            {{ orderEntry.product_entry.name }} - {{ orderEntry.value }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="newEntryDialog = false"
        >
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          text
          :disabled="settable"
          @click="submitOrderEntry"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'OrderEntryAdd',
    data () {
      return {
        newEntryDialog: false,
        productEntrySlots: [],
        settable: false,
        entries: [],
        orderEntryConflicts: [],
      }
    },
    computed: {
      ...mapGetters(['selectedOrder']),
    },
    mounted () {
      this.selectedOrder.products.map(product => {
        if (product.product_entry) {
          product.product_entry.forEach(productEntry => {
            if (productEntry.visibility === 'Hidden') {
              this.productEntrySlots.push({ ...productEntry })
            }
          })
        }
      })
      this.selectedOrder.club_products.map(product => {
        if (product.product_entry) {
          product.product_entry.forEach(productEntry => {
            if (productEntry.visibility === 'Hidden') {
              this.productEntrySlots.push({ ...productEntry })
            }
          })
        }
      })

      this.productEntrySlots.forEach(productEntry => {
        if (productEntry.prefix.length <= 1) {
          this.entries.push({ prefix: productEntry.prefix[0], value: '', product_entry: productEntry._id })
        } else {
          this.entries.push({ prefix: '', value: '', product_entry: productEntry._id })
        }
      })
      this.fetchOrderEntryConflicts(this.$route.params.id).then((result) => {
        this.orderEntryConflicts = result.data
      })
    },
    methods: {
      ...mapActions(['updateOrder', 'fetchOrderEntryConflicts']),
      submitOrderEntry () {
        this.newEntryDialog = false
        if (this.selectedOrder.order_entry) {
          this.selectedOrder.order_entry.forEach(entry => this.entries.push(entry))
          this.updateOrder({
            orderId: this.selectedOrder._id,
            body: { order_entry: this.entries },
          })
        }
      },
    },
  }
</script>

<style>

</style>
