<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar
                flat
              >
                <v-spacer />
                <template v-if="selectedOrder.order_status === 'Pending'">
                  <v-snackbar
                    v-model="showApproveFailureError"
                    timeout="5000"
                  >
                    <template>
                      <h4>Cannot Approve Order!</h4>
                      The user has not entered payment details yet for this order!
                      <div>Order has not been approved.</div>
                    </template>
                  </v-snackbar>
                  <v-snackbar
                    v-model="orderEntrySnackbar"
                    timeout="5000"
                  >
                    <template>
                      <h4>There are Order entry conflicts!</h4>

                      Please address these via "ASSIGN ENTRY VALUES" in the order entry tab before approving the order.

                      <div>Order has not been approved.</div>
                    </template>
                  </v-snackbar>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="green darken-2"
                    @click="approveOrder"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon> Approve
                  </v-btn>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="red darken-2"
                    @click="rejectOrder"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon> Reject
                  </v-btn>
                  <order-entry-add v-if="defaultClientDetailType === 'Order Entry'" />
                </template>
              </v-toolbar>

              <v-toolbar flat>
                <v-toolbar-title>Order Details</v-toolbar-title>
                <v-spacer />
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ defaultClientDetailType }}
                      <v-icon>mdi-menu-down-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in clientDetailTypes"
                      :key="i"
                    >
                      <v-list-item-title @click="changeClientDetailsScreen(item)">
                        {{ item }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <template v-if="defaultClientDetailType === 'General'">
                    <v-row>
                      <v-col
                        v-if="selectedOrder.person"
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Registrant Name
                        </h3>
                        <br>
                        <p>
                          {{ selectedOrder.person.contact.givenName }} {{ selectedOrder.person.contact.surname }}
                        </p>
                        <!-- <p v-if="selectedOrder.person.contact.preferredName">
                          {{ selectedOrder.person.contact.preferredName || "Error Registrant Preferred Name not defined" }}
                        </p>
                        <p v-if="!selectedOrder.person.contact.preferredName && selectedOrder.person.contact.givenName && selectedOrder.person.contact.surname">
                        </p>
                        <p v-if="!selectedOrder.person.contact.preferredName && !selectedOrder.person.contact.givenName && !selectedOrder.person.contact.surname">
                          No Name Provided
                        </p> -->
                        <v-divider />
                      </v-col>
                      <v-col
                        v-if="selectedOrder.package"
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Package
                        </h3>
                        <br>
                        <user-item
                          field-name="surname"
                          :field-data="selectedOrder.product_name"
                        />
                        <v-divider />
                      </v-col>
                      <v-col
                        v-if="selectedOrder.final_price"
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Package Price
                        </h3>
                        <br>
                        <p>{{ formatCurrency(selectedOrder.final_price) || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        v-if="selectedOrder.payment_status"
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Order Status
                        </h3>
                        <br>
                        <p>{{ selectedOrder.order_status || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        v-if="selectedOrder.payment_status"
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Payment Status
                        </h3>
                        <br>
                        <p>{{ selectedOrder.payment_status || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        v-if="selectedOrder.payment_status"
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Medical Document
                        </h3>
                        <br>
                        <p v-if="orderMedical === null">
                          NOT PROVIDED
                        </p>
                        <p v-if="orderMedical !== null">
                          <v-btn
                            outlined
                            class="mr-4"
                            color="green darken-2"
                            text="Download"
                            @click="downloadMedicalFile()"
                          >
                            Download
                          </v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Order Entry'">
                    <v-row>
                      <template v-for="entry in selectedOrder.order_entry">
                        <v-col
                          v-if="selectedOrder.order_entry"
                          :key="entry._id"
                          cols="12"
                          md="4"
                        >
                          <h3
                            v-if="entry.product_entry"
                            class="darken-2"
                            style="font-size: 1rem; color: grey"
                          >
                            {{
                              entry.product_entry.name
                            }}

                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="orderEntryIsConflicted(entry)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-clipboard-remove
                                </v-icon>
                              </template>
                              <span>This value must be unique and another order has taken this value!</span>
                            </v-tooltip>
                          </h3>
                          <h3
                            v-else
                            class="darken-2"
                            style="font-size: 1rem; color: grey"
                          >
                            Product Entry not associated to this order.
                          </h3>
                          <br>
                          <user-item
                            field-name="Entry Value"
                            :field-data="entry.value"
                            :meta="{order:selectedOrder, orderEntry:entry }"
                          />
                          <v-divider />
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Products'">
                    <v-row v-if="selectedOrder.products || selectedOrder.club_products">
                      <v-col cols="12">
                        <h3>Direct Products</h3>
                      </v-col>
                      <template v-for="product in selectedOrder.products">
                        <div :key="product._id">
                          <v-col
                            cols="12"
                            md="8"
                          >
                            <h3
                              class="darken-2"
                              style="font-size: 1rem; color: grey"
                            >
                              Product Name
                            </h3>
                            <br>
                            {{ product.name }}
                            <v-divider />
                          </v-col>
                          <!-- <v-col
                            cols="12"
                            md="8"
                          >
                            <h3
                              class="darken-2"
                              style="font-size: 1rem; color: grey"
                            >
                              Requested ID
                            </h3>
                            <br>
                            <user-item
                              field-name="Requested ID"
                              :field-data="product.requestedId"
                            />
                            <v-divider />
                          </v-col>a -->
                        </div>
                      </template>
                      <v-col cols="12">
                        <h3>Club Products</h3>
                      </v-col>
                      <template v-for="product in selectedOrder.club_products">
                        <div :key="product._id">
                          <v-col
                            cols="12"
                            md="8"
                          >
                            <h3
                              class="darken-2"
                              style="font-size: 1rem; color: grey"
                            >
                              Product Name
                            </h3>
                            <br>
                            {{ product.name }}
                            <v-divider />
                          </v-col>
                        </div>
                      </template>
                    </v-row>
                    <v-row v-else>
                      <v-container>
                        <p class="text-center">
                          No Products Provided
                        </p>
                      </v-container>
                    </v-row>
                  </template>
                  <template v-if="defaultClientDetailType === 'Health Statement'">
                    <v-row v-if="selectedOrder.health_statement">
                      <v-col cols="12">
                        <h3>Doctor Information</h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Doctor Full Name
                        </h3>
                        <br>
                        <p>{{ selectedOrder.health_statement.doctor_full_name || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Practice
                        </h3>
                        <br>
                        <p>{{ selectedOrder.health_statement.doctor_practice || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Phone
                        </h3>
                        <br>
                        <p>{{ selectedOrder.health_statement.doctor_phone || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Address
                        </h3>
                        <br>
                        <p><span style="color: white">Address Line 1:</span> {{ selectedOrder.health_statement.doctor_address_line_1 }} </p>
                        <p><span style="color: white">Address Line 2:</span> {{ selectedOrder.health_statement.doctor_address_line_2 }} </p>
                        <p><span style="color: white">Suburb:</span> {{ selectedOrder.health_statement.doctor_suburb }} </p>
                        <p><span style="color: white">State:</span> {{ selectedOrder.health_statement.doctor_state }} </p>
                        <p><span style="color: white">Postcode:</span> {{ selectedOrder.health_statement.doctor_postcode }}</p>
                        <v-divider />
                      </v-col>
                      <v-col cols="12">
                        <h3>Registrant Health Information</h3>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Medication
                        </h3>
                        <br>
                        <p v-if="!selectedOrder.health_statement.medication">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          No Medication
                        </p>
                        <template v-if="selectedOrder.health_statement.medication == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Medication Present
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.medication_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Allergies
                        </h3>
                        <br>
                        <p v-if="!selectedOrder.health_statement.allergies">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          No Allergies
                        </p>
                        <template v-if="selectedOrder.health_statement.allergies == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Allergies Present
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.allergies_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Recent Operations
                        </h3>
                        <br>
                        <p v-if="!selectedOrder.health_statement.recentOperations">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          No Recent Operations
                        </p>
                        <template v-if="selectedOrder.health_statement.recentOperations == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Recent Operations
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.recentOperations_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Specialised Illness
                        </h3>
                        <br>
                        <p v-if="!selectedOrder.health_statement.specialisedIllness">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          No Specialised Illnesses
                        </p>
                        <template v-if="selectedOrder.health_statement.specialisedIllness == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Specialised Illnesses
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.specialisedIllness_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Conditions
                        </h3>
                        <br>
                        <p v-if="!selectedOrder.health_statement.conditions">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          No Conditions
                        </p>
                        <template v-if="selectedOrder.health_statement.conditions == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Conditions
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.conditions_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Vision
                        </h3>
                        <br>
                        <p v-if="!selectedOrder.health_statement.vision">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          No Vision Issues
                        </p>
                        <template v-if="selectedOrder.health_statement.vision == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Vision Issues
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.vision_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Smoker
                        </h3>
                        <br>
                        <p v-if="selectedOrder.health_statement.smoker == false">
                          <v-icon
                            class="mr-2"
                          >
                            mdi-check
                          </v-icon>
                          Non Smoker
                        </p>
                        <template v-if="selectedOrder.health_statement.smoker == true">
                          <p>
                            <v-icon
                              class="mr-2"
                            >
                              mdi-alert
                            </v-icon>
                            Smoker
                          </p>
                          <p>Details: {{ selectedOrder.health_statement.smoker_input || "Not Provided" }}</p>
                        </template>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Accepted Declaration
                        </h3>
                        <br>
                        <v-icon v-if="selectedOrder.health_statement.declaration_accepted">
                          mdi-check
                        </v-icon>
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-container>
                        <p class="text-center">
                          No Health Statement Provided
                        </p>
                      </v-container>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { utilities } from '../../../../mixins/utility'
  import { mapActions, mapGetters } from 'vuex'
  import UserItem from './itemUpdate.vue'
  import OrderEntryAdd from './OrderEntryAdd.vue'

  export default {
    name: 'Registrant',
    components: {
      UserItem,
      OrderEntryAdd,
    },
    mixins: [
      utilities,
    ],
    data () {
      return {
        orderMedical: null,
        orderEntrySnackbar: false,
        orderEntryConflicts: [],
        showApproveFailureError: false,
        page: {
          title: 'Your Roster',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Users',
            disabled: false,
            to: '/user/users/',
          },
          {
            text: 'User Details',
            disabled: true,
          },
        ],
        auditHeaders: [
          {
            text: 'Reason',
            value: 'reason',
          },
          {
            text: 'Resource',
            value: 'route',
          },
          {
            text: 'Method',
            value: 'method',
          },
          {
            text: 'When',
            value: 'createdAt',
          },
        ],
        clientDetailTypes: [
          'General',
          'Order Entry',
          'Health Statement',
          'Products',
        ],
        defaultClientDetailType: 'General',
      }
    },
    created () {
      this.viewOrder(this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedOrder']),
    },
    mounted () {
      this.fetchOrderEntryConflicts(this.$route.params.id).then((conflicts) => {
        if (conflicts.data.length > 0) {
          this.orderEntryConflicts = conflicts.data
        }
      })

      this.fetchOrderMedical(this.$route.params.id).then((medical) => {
        this.orderMedical = medical
      })
    },
    methods: {
      ...mapActions(['viewOrder', 'fetchOrderEntryConflicts', 'fetchOrderMedical']),
      back () {
        this.$router.back()
      },
      downloadMedicalFile () {
        const element = document.createElement('a')
        element.href = this.orderMedical.data
        element.setAttribute('download', `${this.$route.params.id}-Medical`)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
      },
      base64toBlob (base64Data, contentType = '') {
        const sliceSize = 1024
        const byteCharacters = atob(base64Data)
        const bytesLength = byteCharacters.length
        const slicesCount = Math.ceil(bytesLength / sliceSize)
        const byteArrays = new Array(slicesCount)

        for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
          const begin = sliceIndex * sliceSize
          const end = Math.min(begin + sliceSize, bytesLength)

          const bytes = new Array(end - begin)
          for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
            bytes[i] = byteCharacters[offset].charCodeAt(0)
          }

          byteArrays[sliceIndex] = new Uint8Array(bytes)
        }

        return new Blob(byteArrays, { type: contentType })
      },
      changeClientDetailsScreen (screen) {
        this.defaultClientDetailType = screen
      },
      approve () {
        this.$store.dispatch('approveRegistrant', this.registrantQuery._id)
      },
      async approveOrder () {
        this.fetchOrderEntryConflicts(this.$route.params.id).then((conflicts) => {
          if (conflicts.data.length > 0) {
            this.orderEntrySnackbar = true
            this.orderEntryConflicts = conflicts.data
            return
          }

          this.$store.dispatch('approveOrder', this.$route.params.id).catch((err) => {
            this.showApproveFailureError = true
            console.error('error', err)
          })
        })
      },
      rejectOrder () {
      },
      refreshAudit () {
        this.$store.dispatch('showUserAudit')
      },
      orderEntryIsConflicted (entry) {
        return !!this.orderEntryConflicts.find((conflict) => conflict.product_entry._id === entry.product_entry._id)
      },
    },
  }
</script>
