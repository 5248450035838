<template>
  <v-edit-dialog
    :return-value.sync="fieldData"
    large
    persistent
    @save="saveFork"
  >
    <p>{{ fieldData || "Not Provided" }}</p>
    <template v-slot:input>
      <div class="mt-4 text-h6">
        Update {{ fieldName }}
      </div>
      <v-text-field
        v-model="fieldData"
        autofocus
        label="Edit"
      />
    </template>
    <v-snackbar
      v-model="showErrorDuplicate"
      timeout="3000"
    >
      <template>
        <h4>Duplicate Order Entry!</h4>
        The value you selected already exists on another order and must be unique!
        <div>Order has not been modified.</div>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showErrorApproved"
      timeout="3000"
    >
      <template>
        <h4>Order already approved!</h4>
        You cannot change the assigned order entry to an already approved order!
        <div>Order has not been modified.</div>
      </template>
    </v-snackbar>
  </v-edit-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'UserItem',
    props: {
      fieldName: String,
      fieldData: String,
      meta: Object,
    },
    computed: {
      ...mapGetters(['selectedUser']),
    },
    date () {
      return {
        oldFieldValue: null,
        showErrorDuplicate: false,
        showSuccess: false,
        showErrorApproved: false,
      }
    },
    mounted () {
      this.oldFieldValue = this.fieldData
    },
    methods: {
      ...mapActions(['updateOrderEntry']),

      saveFork () {
        // Forks save function to not just edit user details,
        // can fork to edit orderEntries
        if (!this.meta) {
          return this.save()
        } else {
          const order = this.meta.order
          const orderEntry = this.meta.orderEntry
          this.updateOrderEntry({ orderId: order._id, orderEntryId: orderEntry._id, newValue: this.fieldData }).then().catch((err) => {
            if (err.response.status === 400) {
              this.fieldData = this.oldFieldValue
              this.showErrorDuplicate = true
              throw new Error('Can\'t edit!, number already taken!')
            }

            if (err.response.status === 406) {
              this.fieldData = this.oldFieldValue
              this.showErrorApproved = true
              throw new Error('Can\'t edit!, already approved')
            }
          })
        }
      },
      save () {
        const role = this.selectedUser.role
        const id = this.selectedUser.id
        const userUpdateDetails = {
          id: id,
          role: role,
        }
        userUpdateDetails[this.fieldName] = this.fieldData
        // console.log(userUpdateDetails)
        this.$store.dispatch('updateUser', userUpdateDetails)
      },
    },
  }
</script>

<style>

</style>
